"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWorkspaceUrl = exports.upgradeUrl = exports.search = exports.portalRelativePath = exports.portalVMUsage = exports.portalVMSettings = exports.portalPermissions = exports.portalRegistry = exports.portalOverview = exports.liked = exports.shared = exports.deleted = exports.getStarted = exports.recent = exports.templates = exports.syncedSandboxes = exports.repository = exports.repositories = exports.myContributions = exports.drafts = exports.sandboxes = exports.ALL_SANDBOXES_URL_PREFIX = exports.DASHBOARD_URL_PREFIX = void 0;
exports.DASHBOARD_URL_PREFIX = '/dashboard';
exports.ALL_SANDBOXES_URL_PREFIX = `${exports.DASHBOARD_URL_PREFIX}/sandboxes`;
function appendTeamIdQueryParam(url, teamId) {
    if (teamId) {
        return `${url}?workspace=${teamId}`;
    }
    return url;
}
function sanitizePath(path) {
    return path
        .split('/')
        .map(p => p.split(' ').map(encodeURIComponent).join(' '))
        .join('/');
}
const sandboxes = (path, teamId) => appendTeamIdQueryParam(`${exports.ALL_SANDBOXES_URL_PREFIX}${sanitizePath(path)}`, teamId);
exports.sandboxes = sandboxes;
const drafts = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/drafts`, teamId);
exports.drafts = drafts;
const myContributions = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/my-contributions`, teamId);
exports.myContributions = myContributions;
const repositories = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/repositories`, teamId);
exports.repositories = repositories;
const repository = ({ owner, name, teamId, }) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/repositories/github/${owner}/${name}`, teamId);
exports.repository = repository;
const syncedSandboxes = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/synced-sandboxes`, teamId);
exports.syncedSandboxes = syncedSandboxes;
const templates = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/templates`, teamId);
exports.templates = templates;
const recent = (teamId, extraParams) => {
    let recentUrl = appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/recent`, teamId);
    if (extraParams && Object.keys(extraParams).length > 0) {
        const params = new URLSearchParams(extraParams);
        if (recentUrl.includes('?')) {
            recentUrl += '&';
        }
        else {
            recentUrl += '?';
        }
        recentUrl += params.toString();
    }
    return recentUrl;
};
exports.recent = recent;
const getStarted = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/get-started`, teamId);
exports.getStarted = getStarted;
const deleted = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/deleted`, teamId);
exports.deleted = deleted;
const shared = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/shared`, teamId);
exports.shared = shared;
const liked = (teamId) => appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/liked`, teamId);
exports.liked = liked;
const portalBaseUrl = () => {
    const origin = process.env.ENDPOINT || window.location.origin;
    return `${origin}/t`;
};
const portalOverview = (teamId, params) => {
    if (!teamId) {
        return `${portalBaseUrl()}/overview`;
    }
    const url = appendTeamIdQueryParam(`${portalBaseUrl()}/overview`, teamId);
    const searchParams = new URLSearchParams(params);
    return `${url}&${searchParams.toString()}`;
};
exports.portalOverview = portalOverview;
const portalRegistry = (teamId) => appendTeamIdQueryParam(`${portalBaseUrl()}/registry`, teamId);
exports.portalRegistry = portalRegistry;
const portalPermissions = (teamId) => appendTeamIdQueryParam(`${portalBaseUrl()}/permissions`, teamId);
exports.portalPermissions = portalPermissions;
const portalVMSettings = (teamId) => appendTeamIdQueryParam(`${portalBaseUrl()}/vm_settings`, teamId);
exports.portalVMSettings = portalVMSettings;
const portalVMUsage = (teamId) => appendTeamIdQueryParam(`${portalBaseUrl()}/usage`, teamId);
exports.portalVMUsage = portalVMUsage;
// This is used separately for checkout endpoints where the success/cancel paths need to be relative
const portalRelativePath = (teamId) => appendTeamIdQueryParam(`/t/overview`, teamId);
exports.portalRelativePath = portalRelativePath;
const search = (query, teamId) => {
    let searchUrl = appendTeamIdQueryParam(`${exports.DASHBOARD_URL_PREFIX}/search`, teamId);
    if (searchUrl.includes('?')) {
        searchUrl += '&';
    }
    else {
        searchUrl += '?';
    }
    searchUrl += `query=${query}`;
    return searchUrl;
};
exports.search = search;
const upgradeUrl = ({ workspaceId, source, } = {}) => {
    const searchQuery = new URLSearchParams({});
    if (workspaceId) {
        searchQuery.set('workspace', workspaceId);
    }
    if (source) {
        searchQuery.set('utm_source', source);
    }
    const queryString = searchQuery.toString();
    return queryString ? `/upgrade?${queryString}` : '/upgrade';
};
exports.upgradeUrl = upgradeUrl;
const createWorkspaceUrl = ({ workspaceId, }) => {
    const searchQuery = new URLSearchParams();
    if (workspaceId) {
        searchQuery.set('workspace', workspaceId);
    }
    const queryString = searchQuery.toString();
    return queryString ? `/create-workspace?${queryString}` : '/create-workspace';
};
exports.createWorkspaceUrl = createWorkspaceUrl;
