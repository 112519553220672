"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isServer = void 0;
const SERVER_TEMPLATE_NAMES = [
    'adonis',
    'apollo',
    'docusaurus',
    'ember',
    'gatsby',
    'gridsome',
    'marko',
    'mdx-deck',
    'nest',
    'next',
    'node',
    'nuxt',
    'quasar',
    'remix',
    'sapper',
    'styleguidist',
    'unibit',
    'vuepress',
];
const isServer = (template) => SERVER_TEMPLATE_NAMES.indexOf(template) !== -1;
exports.isServer = isServer;
